<template>
    <v-card v-if="project" flat>
        <v-card-title class="pt-0 subtitle-1" v-if="withTitle">
            <v-icon left>mdi-file-tree</v-icon>
            <span v-text="$t('issues.menu.linkedIssues')" />
        </v-card-title>
        <v-card-text>
            <v-toolbar dense flat color="transparent" class="pa-0">
                <v-spacer />
                <v-btn
                    color="primary"
                    small
                    outlined
                    v-text="$t('buttons.createIssueAssociated')"
                    @click="createChild"
                />
            </v-toolbar>
        </v-card-text>
        <v-card-text v-show="issues > 0">
            <issues-groups
                v-if="displayGroup"
                :view.sync="view"
                :filters.sync="filters"
                v-on:update-group="updateGroup"
            />
            <issues-view
                :view.sync="view"
                :filters.sync="filters"
                :group.sync="group"
                :allow-select="false"
                :show-footer="true"
                v-on:total-issues="updateTotalIssues"
            />
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import issuesGroups from './issue/groups';
import issuesView from './issue/view';

export default {
    name: 'issue-children',
    data: () => ({
        displayGroup: true,
        group: undefined,
        issues: 0,
    }),
    components: {
        issuesGroups,
        issuesView,
    },
    props: {
        issue: {
            default: undefined,
        },
        withTitle: {
            default: false,
        },
    },
    computed: {
        ...mapGetters(['project']),
        view() {
            return this.project.views.find((v) => v.name === 'linkedIssues');
        },
        filters() {
            if (this.issue) {
                return [
                    {
                        field: 'parent',
                        operator: '$eq',
                        value: this.issue.id,
                    },
                ];
            }
            return [];
        },
    },
    methods: {
        updateGroup(group) {
            this.group = group;
        },
        updateTotalIssues(count) {
            this.issues = count;
        },
        createChild() {
            this.$router.push({
                name: 'project-issue-create',
                params: { project_id: this.$route.params.project_id },
                query: { parent: this.issue.id },
            });
        },
    },
};
</script>

<style></style>
