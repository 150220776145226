<template>
    <div>
        <journal v-for="journal in journals" :key="journal.id" :journal="journal" :issue="issue" />
    </div>
</template>

<script>
import journal from '@/components/issues/journal';
import startOfDay from 'date-fns/startOfDay';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';

export default {
    name: 'issue-activity',
    data: () => ({}),
    props: {
        issue: {},
    },
    components: {
        journal,
    },
    computed: {
        journals() {
            const journalToSend = this.issue.journal.map((j) => {
                return {
                    createdBy: j.createdBy,
                    createdAt: j.createdAt,
                    attributes: j.attributes,
                    sortDate: startOfDay(new Date(j.createdAt)),
                    id: j.id,
                };
            });
            const grouped = groupBy(journalToSend, (j) => j.sortDate);
            const journalOptimize = [];
            for (let g in grouped) {
                let j = {
                    createdAt: g,
                    createdBy: [...new Set(grouped[g].map((i) => i.createdBy))],
                    attributes: grouped[g].map((i) => i.attributes).reduce((a, b) => a.concat(b), []),
                };

                journalOptimize.push(j);
            }

            return journalOptimize;
        },
    },
};
</script>

<style></style>
