<template>
    <v-card v-if="project" flat>
        <v-card-text>
            <markdown v-model="comment.description" :small="true" />
            <v-container>
                <v-row>
                    <v-col cols="2">
                        <v-checkbox
                            v-if="allowPrivate"
                            v-model="comment.private"
                            :label="$t('issues.comments.attributes.private')"
                        ></v-checkbox>
                    </v-col>
                    <v-col>
                        <v-select
                            v-if="allowPrivate"
                            v-model="comment.tags"
                            :items="tags"
                            :label="$t('issues.comments.attributes.tags')"
                            multiple
                            chips
                        >
                        </v-select>
                    </v-col>
                </v-row>
            </v-container>

            <v-btn small color="success" outlined :class="allowPrivate ? '' : 'mt-4'" @click="addComment">
                {{ $t('buttons.addComment') }}
            </v-btn>
        </v-card-text>
    </v-card>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import markdown from '@/components/shared/editor';
import { projectMemberMixin } from '@/mixins/member';

export default {
    name: 'issue-addComment',
    data: () => ({
        comment: {
            description: '',
            private: false,
            tags: [],
        },
    }),
    mixins: [projectMemberMixin],
    props: {
        issue: {},
        allowPrivate: {
            default: false,
        },
    },
    components: { markdown },
    computed: {
        ...mapGetters(['project', 'commentTags']),
        tags() {
            return this.commentTags.map((t) => {
                return {
                    text: this.$t('lists.commentTag.' + t),
                    value: t,
                };
            });
        },
    },
    methods: {
        ...mapActions(['addCommentToIssue']),
        initComment() {
            this.comment = {
                description: '',
                private: false,
                tags: [],
            };
        },
        async addComment() {
            await this.addCommentToIssue({
                id: this.issue.id,
                comment: this.comment,
            });
            this.initComment();

            this.$emit('change-attribute-value');
        },
    },
};
</script>
