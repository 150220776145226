<template>
    <v-card v-if="project" flat>
        <v-card-title class="subtitle-2">
            <v-toolbar dense flat class="comment-title">
                <label-user :value="comment.user" :avatar="true" :size="24" />
                <v-spacer />
                <v-btn
                    v-if="allowEditComment"
                    x-small
                    text
                    v-text="$t('buttons.edit')"
                    @click="modifyComment(comment)"
                ></v-btn>
            </v-toolbar>
        </v-card-title>
        <v-card-subtitle class="caption">
            <label-date :value="comment.createdAt" />
        </v-card-subtitle>
        <v-card-text v-if="editComment">
            <markdown v-model="editComment.description" v-on:save-content="saveComment" :small="true" />
            <v-select
                class="mt-4"
                v-show="allowPrivate"
                v-model="editComment.tags"
                :items="tags"
                :label="$t('issues.comments.attributes.tags')"
                multiple
                chips
            >
            </v-select>
            <v-toolbar dense flat>
                <v-spacer />
                <v-btn small color="red" text v-text="$t('buttons.cancel')" @click="initComment" />
                <v-btn small color="success" text v-text="$t('buttons.updateComment')" @click="saveComment" />
            </v-toolbar>
        </v-card-text>
        <v-card-text v-else>
            <v-alert v-if="comment.private" icon="mdi-lock" color="yellow darken-3" outlined border="left">
                <labelMarkdown :md="comment.description" />
            </v-alert>
            <labelMarkdown v-else :md="comment.description" />
        </v-card-text>
        <v-card-text v-if="!editComment && comment.tags.length > 0" class="px-0 pt-0">
            <v-chip-group>
                <v-chip v-if="comment.tags.includes('close')" color="indigo darken-2" dark label x-small>
                    <span class="font-weight-bold">{{ $t('lists.commentTag.close') }}</span>
                </v-chip>
                <v-chip v-if="comment.tags.includes('relaunch')" color="red darken-2" dark label x-small>
                    <span class="font-weight-bold">{{ $t('lists.commentTag.relaunch') }}</span>
                </v-chip>
            </v-chip-group>
        </v-card-text>
        <v-divider />
    </v-card>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import labelUser from '@/components/labels/user';
import labelDate from '@/components/labels/date';
import labelMarkdown from '@/components/labels/markdown';
import markdown from '@/components/shared/editor';
import { projectMemberMixin } from '@/mixins/member';
import Clone from 'lodash/cloneDeep';

export default {
    name: 'issue-comment',
    data: () => ({
        editComment: undefined,
    }),
    mixins: [projectMemberMixin],
    props: {
        issue: {},
        comment: {},
        allowPrivate: {
            default: false,
        },
    },
    components: { labelUser, labelDate, labelMarkdown, markdown },
    computed: {
        ...mapGetters(['project', 'profile', 'commentTags']),
        allowEditComment() {
            return this.comment.user === this.profile.name && this.editComment === undefined;
        },
        tags() {
            return this.commentTags.map((t) => {
                return {
                    text: this.$t('lists.commentTag.' + t),
                    value: t,
                };
            });
        },
    },
    methods: {
        ...mapActions(['updateCommentToIssue']),
        initComment() {
            this.editComment = undefined;
        },
        modifyComment() {
            this.editComment = Clone(this.comment);
        },
        async saveComment() {
            await this.updateCommentToIssue({
                id: this.issue.id,
                comment: this.editComment,
            });
            this.initComment();

            this.$emit('change-attribute-value');
        },
    },
};
</script>
