<template>
    <v-card flat tile>
        <v-card-text>
            <add-tracking :issue="issue" />
        </v-card-text>
        <v-card-text>
            <tracking-list :issue="issue" />
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import addTracking from '@/components/issues/addTracking';
import trackingList from '@/components/issues/trackings';

export default {
    name: 'issue-tracking',
    data: () => ({
        updateTracking: false,
        editTracking: {
            date: new Date(),
        },
    }),
    props: {
        issue: {},
    },
    components: { addTracking, trackingList },
    computed: {
        ...mapGetters(['profile']),
    },
};
</script>

<style></style>
