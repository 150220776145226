<template>
    <v-card class="mb-2" outlined>
        <v-list-item>
            <v-list-item-content>
                <v-list-item-title class="body-2">
                    <label-user v-for="(c, i) in journal.createdBy" :key="i" :value="c" class="mr-2" />
                </v-list-item-title>
                <v-list-item-subtitle class="caption align-end">
                    <v-icon size="14"> mdi-calendar-clock </v-icon>
                    <label-date :value="journal.createdAt" format="dd/MM/yyyy" />
                </v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>

        <v-card-text class="py-0">
            <v-list dense>
                <v-list-item v-for="(attribute, index) in journal.attributes" :key="index">
                    <v-list-item-content
                        class="font-weight-medium blue-grey--text text--darken-3 py-0"
                        v-text="$t(`issues.attributes.${attribute.name}`)"
                    />
                    <v-list-item-content class="align-end py-0">
                        <span
                            class="text-decoration-line-through"
                            v-text="valueToLabel(attribute.name, attribute.oldValue)"
                        >
                        </span>
                    </v-list-item-content>
                    <v-list-item-content class="align-end py-0">
                        <span v-text="valueToLabel(attribute.name, attribute.newValue)"> </span>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-card-text>
    </v-card>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import labelUser from '@/components/labels/simpleUser';
import labelDate from '@/components/labels/date';
import { formatDateMixin } from '@/mixins/dates';

export default {
    name: 'issue-journal',
    data: () => ({}),
    mixins: [formatDateMixin],
    props: {
        issue: {},
        journal: {},
    },
    components: { labelUser, labelDate },
    computed: {
        ...mapGetters(['projectUsers']),
    },
    methods: {
        valueToLabel(attribute, valueToCheck) {
            let valueL = [valueToCheck];
            let value = valueToCheck;
            if (typeof value === 'string') {
                value = value.split(',');
            }
            if (typeof value === 'number') {
                value = [valueToCheck];
            }
            if (value instanceof Array) {
                valueL = value.map((v) => {
                    if (this.listValue(attribute)) {
                        return this.$t(this.listValue(attribute) + v);
                    } else if (this.listUser(attribute)) {
                        return this.projectUsers.filter((u) => u.value === v)[0].text;
                    } else {
                        return v;
                    }
                });
            }
            if (valueToCheck instanceof Date) {
                valueL = [format(value, 'dd/MM/yyyy')];
            }

            const returnValue = valueL.join(', ');

            return returnValue ? returnValue : '-';
        },
        listValue(attribute) {
            if (attribute === 'tracker') {
                return 'lists.trackers.';
            }
            if (attribute === 'status') {
                return 'lists.status.';
            }
            if (attribute === 'severity') {
                return 'lists.severities.';
            }
            if (attribute === 'priority') {
                return 'lists.priorities.';
            }
            return undefined;
        },
        listUser(attribute) {
            return attribute === 'assignedTo' || attribute === 'createdBy';
        },
    },
};
</script>
