<template>
    <markdown
        :value="issue.releaseNoteDetail"
        v-on:save-content="changeAttribute"
        :label="$t('issues.actions.add_releaseDetail')"
        :allow-editing="this.allowEditing"
    />
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import markdown from '@/components/shared/markdown';

export default {
    name: 'issue-release-detail',
    data: () => ({
        editingValue: undefined,
    }),
    props: {
        issue: {},
        allowEditing: {
            default: true,
        },
    },
    components: {
        markdown,
    },
    computed: {
        ...mapGetters(['profile']),
    },
    methods: {
        ...mapActions(['updateIssue']),
        async changeAttribute(value) {
            await this.updateIssue({
                id: this.issue.id,
                releaseNoteDetail: value,
            });

            this.editing = false;
            this.$emit('change-attribute-value');
        },
    },
};
</script>
