<template>
    <v-container class="py-0">
        <v-row v-if="!editing">
            <v-toolbar dense flat color="transparent">
                <a @click="editValue" v-text="title" class="blue-grey--text text--darken-2 subtitle-1" />
            </v-toolbar>
        </v-row>
        <v-row v-if="editing">
            <v-col>
                <v-card flat>
                    <v-card-text>
                        <v-text-field
                            :label="$t('issues.attributes.releaseNote')"
                            v-if="editing"
                            v-model="editingValue"
                            single-line
                            counter="80"
                        ></v-text-field>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="red" text @click="editing = false" v-text="$t('buttons.cancel')" />
                        <v-btn color="success" text @click="changeAttribute" v-text="$t('buttons.save')" />
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
export default {
    name: 'issue-release',
    data: () => ({
        editing: false,
        editingValue: undefined,
    }),
    props: {
        issue: {},
        allowEditing: {
            default: true,
        },
    },
    watch: {
        editing: function (e) {
            if (e) {
                this.editingValue = this.issue.releaseNote;
            }
        },
    },
    computed: {
        title() {
            return this.issue.releaseNote ? this.issue.releaseNote : this.$t('issues.empty.release');
        },
    },
    methods: {
        ...mapActions(['updateIssue']),
        editValue() {
            if (this.allowEditing) {
                this.editing = true;
            }
        },
        async changeAttribute(value) {
            await this.updateIssue({
                id: this.issue.id,
                releaseNote: this.editingValue,
            });

            this.editing = false;
            this.$emit('change-attribute-value');
        },
    },
};
</script>
