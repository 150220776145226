<template>
    <span v-html="userToDisplay ? userToDisplay.fullname : $t('users.not_exist')"></span>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
    name: 'label-user-simple',
    data: () => ({}),
    created() {},
    mounted() {},
    props: {
        value: {},
    },
    watch: {},
    computed: {
        ...mapGetters(['usersLabels']),
        userToDisplay() {
            return this.usersLabels.find((u) => u.name === this.value);
        },
    },
};
</script>
