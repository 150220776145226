<template>
    <v-row v-if="issue && project">
        <v-col cols="12" md="9">
            <v-card>
                <v-card-title>
                    <issue-name
                        :issue="issue"
                        :display-like="true"
                        :display-watch="true"
                        :allow-editing="allowEditingAttribute('name')"
                        classContainer=""
                    />
                </v-card-title>
                <v-card-subtitle>
                    {{ $t('issues.actions.added_by') }}
                    <label-user :value="issue.createdBy" />
                    &nbsp;
                    {{ $t('issues.actions.added_at') }}
                    <issue-created-at :issue="issue" />
                </v-card-subtitle>
                <v-card-text>
                    <issue-description :issue="issue" :allow-editing="allowEditingAttribute('description')" />
                </v-card-text>
                <v-divider />
                <v-card-text>
                    <v-tabs vertical>
                        <v-tab
                            v-if="isIssueReleaseNote && project.versions.length > 0"
                            class="justify-start"
                            href="#issue-release"
                        >
                            <v-icon left>mdi-package-variant</v-icon>
                            <span class="caption" v-text="$t('issues.menu.release')" />
                        </v-tab>
                        <v-tab class="justify-start" href="#issue-comments">
                            <v-icon left>mdi-comment</v-icon>
                            <span class="caption" v-text="$t('issues.menu.comments')" />
                        </v-tab>
                        <v-tab class="justify-start" href="#issue-tasks" v-if="allowEditingAttribute('tasks')">
                            <v-badge color="success" dot :value="issue.tasks.length > 0">
                                <v-icon left>mdi-format-list-checks</v-icon>
                                <span class="caption" v-text="$t('issues.menu.tasks')" />
                            </v-badge>
                        </v-tab>
                        <v-tab class="justify-start" href="#issue-children">
                            <v-badge color="success" dot :value="issue.childrenCount > 0">
                                <v-icon left>mdi-file-tree</v-icon>
                                <span class="caption" v-text="$t('issues.menu.linkedIssues')" />
                            </v-badge>
                        </v-tab>
                        <v-tab class="justify-start" href="#issue-files">
                            <v-badge color="red" dot :value="issue.files.length > 0">
                                <v-icon left>mdi-paperclip</v-icon>
                                <span class="caption" v-text="$t('issues.menu.files')" />
                            </v-badge>
                        </v-tab>
                        <v-tab class="justify-start" href="#issue-times" v-if="allowEditingAttribute('times')">
                            <v-icon left>mdi-timer</v-icon>
                            <span class="caption" v-text="$t('issues.menu.times')" />
                        </v-tab>
                        <v-tab class="justify-start" href="#issue-timeline" v-if="issue.journal.length > 0">
                            <v-icon left>mdi-timeline</v-icon>
                            <span class="caption" v-text="$t('issues.menu.activities')" />
                        </v-tab>

                        <v-tab-item value="issue-release">
                            <issue-release :issue="issue" :allow-editing="allowEditingAttribute('releaseNote')" />
                            <issue-release-detail
                                v-if="issue.releaseNote"
                                :issue="issue"
                                :allow-editing="allowEditingAttribute('releaseNoteDetail')"
                            />
                        </v-tab-item>
                        <v-tab-item value="issue-comments">
                            <issue-comments :issue="issue" />
                        </v-tab-item>
                        <v-tab-item value="issue-tasks">
                            <issue-tasks :issue="issue" :allow-editing="allowEditingAttribute('tasks')" />
                        </v-tab-item>
                        <v-tab-item value="issue-children">
                            <issue-children :issue="issue" />
                        </v-tab-item>
                        <v-tab-item value="issue-files">
                            <issue-files :issue="issue" />
                        </v-tab-item>
                        <v-tab-item value="issue-timeline">
                            <issue-activities :issue="issue" />
                        </v-tab-item>
                        <v-tab-item value="issue-times">
                            <issue-times :issue="issue" />
                        </v-tab-item>
                    </v-tabs>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col>
            <v-card>
                <v-card-text>
                    <issue-attributes :issue="issue" />
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { projectMemberMixin } from '@/mixins/member';
import labelUser from '@/components/labels/simpleUser';
import issueAttributes from './attributes.vue';
import issueFiles from './files.vue';
import issueChildren from './children.vue';
import issueRelease from '@/components/issues/release';
import issueReleaseDetail from '@/components/issues/releaseDetail';
import issueComments from './comments.vue';
import issueTasks from './tasks.vue';
import issueActivities from './activities.vue';
import issueTimes from './times.vue';
import issueName from '@/components/issues/name';
import issueCreatedAt from '@/components/issues/createdAt';
import issueDescription from '@/components/issues/description';

export default {
    name: 'project-issue',
    mixins: [projectMemberMixin],
    data: () => ({}),
    components: {
        labelUser,
        issueName,
        issueCreatedAt,
        issueDescription,
        issueAttributes,
        issueFiles,
        issueChildren,
        issueRelease,
        issueReleaseDetail,
        issueComments,
        issueTasks,
        issueActivities,
        issueTimes,
    },
    mounted() {
        this.fetchIssue(this.$route.params.issue_id);
    },
    computed: {
        ...mapGetters(['profile', 'issue', 'project', 'status']),
        isIssueReleaseNote() {
            return this.status.filter((s) => s.releaseNote && s.name === this.issue.status).length > 0;
        },
    },
    methods: {
        ...mapActions(['findIssue']),
        fetchIssue(id) {
            this.findIssue(id);
        },
        allowEditingAttribute(name) {
            const allowByRoles = this.memberRoles.some((r) =>
                ['productOwner', 'productManager', 'developer'].includes(r),
            );
            if (['releaseNote', 'releaseNoteDetail', 'tasks', 'times'].includes(name)) {
                return allowByRoles;
            }

            return allowByRoles || this.issue.createdBy === this.profile.name;
        },
    },
    beforeRouteUpdate(to, from, next) {
        if (to.params.issue_id !== from.params.issue_id) {
            this.fetchIssue(to.params.issue_id);
            next();
        }
    },
};
</script>

<style scoped>
.v-tabs--vertical > .v-tabs-bar .v-tab {
    height: 36px;
}
.v-divider {
    max-width: 80%;
    margin: 0 auto;
}
</style>
