<template>
    <v-card v-if="project" flat>
        <v-card-text>
            <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(addTracking)">
                    <v-row>
                        <v-col>
                            <selector-date v-model="tracking.date" :label="$t('issues.tracking.attributes.date')" />
                        </v-col>
                        <v-col>
                            <ValidationProvider
                                :name="$t('issues.tracking.attributes.time')"
                                :rules="'required'"
                                v-slot="{ errors }"
                            >
                                <v-text-field
                                    :label="$t('issues.tracking.attributes.time')"
                                    v-model="tracking.time"
                                    suffix="h"
                                    :error-messages="errors[0]"
                                    color="red"
                                ></v-text-field>
                            </ValidationProvider>
                        </v-col>
                        <v-col>
                            <selector-activity
                                v-model="tracking.activity"
                                :label="$t('issues.tracking.attributes.activity')"
                            />
                        </v-col>
                    </v-row>
                    <v-btn type="submit" small color="success" outlined>
                        {{ $t('buttons.addTracking') }}
                    </v-btn>
                </form>
            </ValidationObserver>
        </v-card-text>
    </v-card>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { projectMemberMixin } from '@/mixins/member';
import selectorDate from '@/components/selectors/simple/date';
import selectorActivity from '@/components/selectors/simple/timeActivity';
import startOfToday from 'date-fns/startOfToday';

export default {
    name: 'issue-addTracking',
    data: () => ({
        tracking: {
            date: startOfToday(new Date()),
            activity: 'development',
        },
    }),
    mixins: [projectMemberMixin],
    props: {
        issue: {},
    },
    components: { selectorDate, selectorActivity },
    computed: {
        ...mapGetters(['project']),
    },
    methods: {
        ...mapActions(['addTrackingToIssue']),
        initTracking() {
            this.tracking = {
                date: startOfToday(new Date()),
                activity: 'development',
            };
        },
        async addTracking() {
            this.tracking.time = parseFloat(this.tracking.time);
            await this.addTrackingToIssue({
                id: this.issue.id,
                tracking: this.tracking,
            });
            this.initTracking();

            this.$emit('change-attribute-value');
        },
    },
};
</script>
