<template>
    <v-card flat tile>
        <v-card-text v-if="allowEditing">
            <v-text-field
                :placeholder="$t('issues.actions.add_task')"
                v-model="name"
                single-line
                dense
                append-icon="mdi-plus"
                @click:append="addTask"
                @keyup.enter="addTask"
                @keyup.esc="cancelUpdate"
            ></v-text-field>
        </v-card-text>
        <v-card-text>
            <v-simple-table>
                <template v-slot:default>
                    <tbody>
                        <tr v-for="task in tasks" :key="task.id">
                            <td width="1%">
                                <v-checkbox v-if="allowEditing" :value="task.done" @click="doneTask(task)"></v-checkbox>
                            </td>
                            <td width="99%" v-if="!allowEditing && !isEditingTaskSame(task)">
                                <span :class="task.done ? 'task-done' : ''">
                                    {{ task.name }}
                                </span>
                            </td>
                            <td width="99%" v-if="allowEditing && !isEditingTaskSame(task)" class="task-editing">
                                <span @click="editTask(task)" :class="task.done ? 'task-done' : ''">
                                    {{ task.name }}
                                </span>
                            </td>
                            <td width="99%" v-if="isEditingTaskSame(task)">
                                <v-text-field
                                    v-model="editingTask.name"
                                    single-line
                                    dense
                                    append-icon="mdi-cancel"
                                    @click:append="cancelUpdate"
                                    @keyup.enter="updateTask"
                                    @keyup.esc="cancelUpdate"
                                ></v-text-field>
                            </td>
                            <td v-if="allowEditing">
                                <v-btn icon color="grey" @click="removeTask(task.id)">
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'issue-comments',
    data: () => ({
        name: undefined,
        editingTask: undefined,
    }),
    props: {
        issue: {},
        allowEditing: {
            default: false,
        },
    },
    components: {},
    computed: {
        ...mapGetters(['profile']),
        tasks() {
            if (this.issue) {
                return this.issue.tasks;
            }

            return [];
        },
    },
    methods: {
        ...mapActions(['addTaskToIssue', 'updateTaskToIssue', 'deleteTaskToIssue']),
        isEditingTaskSame(task) {
            return this.editingTask && this.editingTask.id === task.id;
        },
        editTask(task) {
            this.editingTask = Object.assign({}, task);
        },
        cancelUpdate() {
            this.editingTask = undefined;
            this.name = undefined;
        },
        addTask() {
            this.addTaskToIssue({
                id: this.issue.id,
                task: {
                    name: this.name,
                },
            });
            this.cancelUpdate();
        },
        updateTask() {
            this.updateTaskToIssue({
                id: this.issue.id,
                task: {
                    id: this.editingTask.id,
                    name: this.editingTask.name,
                },
            });
            this.cancelUpdate();
        },
        doneTask(task) {
            this.updateTaskToIssue({
                id: this.issue.id,
                task: {
                    id: task.id,
                    done: !task.done,
                },
            });
        },
        removeTask(task) {
            this.deleteTaskToIssue({
                id: this.issue.id,
                taskId: task,
            });
        },
    },
};
</script>

<style scoped>
span.task-done {
    text-decoration: line-through;
}
td.task-editing {
    cursor: pointer;
}
</style>
