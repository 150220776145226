<template>
    <v-card flat tile>
        <v-card-text>
            <comment
                v-for="comment in comments"
                :key="comment.id"
                :comment="comment"
                :issue="issue"
                :allowPrivate="allowPrivateComment"
            />
        </v-card-text>
        <v-card-text>
            <add-comment :issue="issue" :allowPrivate="allowPrivateComment" />
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import comment from '@/components/issues/comment';
import addComment from '@/components/issues/addComment';
import { projectMemberMixin } from '@/mixins/member';
import OrderBy from 'lodash/orderBy';

export default {
    name: 'issue-comments',
    data: () => ({
        newComment: {
            description: '',
            private: false,
        },
        editComment: undefined,
    }),
    mixins: [projectMemberMixin],
    props: {
        issue: {},
    },
    components: {
        comment,
        addComment,
    },
    computed: {
        ...mapGetters(['profile']),
        allowPrivateComment() {
            return (
                this.memberRoles.includes('developer') ||
                this.memberRoles.includes('productManager') ||
                this.memberRoles.includes('productOwner')
            );
        },
        comments() {
            return OrderBy(
                this.issue.comments.filter((c) => {
                    return this.allowPrivateComment ? true : c.private === false;
                }),
                ['createdAt'],
                ['asc'],
            );
        },
    },
    methods: {},
};
</script>

<style>
.comment-title .v-toolbar__content {
    padding: 4px 0px;
}
</style>
